import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { Observable } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class UpdateDocumentsService {
  public formtypes:any;
  public headers: HttpHeaders;
  public uploadData: any = [];
  constructor(private http: HttpClient) {
   /* this.formType().subscribe(data => {
        console.log(data)
    });*/
}

  /**
   * formType method
   */
  public formType(accessToken): Observable<any> {
    console.log("in service");
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', accessToken);
    return this.http.get("/v1/contract-form-types", { headers: this.headers});
    /*console.log("data:"+data.formtype);
    this.formtypes = data.formtype;
    console.log("form type length:"+this.formtypes.length);
    return this.formtypes;*/
  }

  public submit(data, accessToken): Observable<any>{
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', accessToken);
    return this.http.post("/v1/contract-forms", data, { headers: this.headers});
  }
}
