import { Component, OnInit, OnDestroy } from '@angular/core';
import { MainDetailsService } from '../main-details.service';
import { UserActivityService } from '../user-activity.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-user-activity-report',
  templateUrl: './user-activity-report.component.html',
  styleUrls: ['./user-activity-report.component.css'],
})
export class UserActivityReportComponent implements OnInit, OnDestroy {
  public data: any;
  public loadingSpinner: boolean = true;
  dtOptions: DataTables.Settings = {};
  public toDate: any; //= moment(new Date());
  public fromDate: any; // = moment(new Date()).add(-1, 'days');
  public format: any = 'json';
  public limit: any = '500';
  public branchDealerCode: any; // = "json";
  public userActivityDataset: any;
  datePickerConfig: Partial<BsDatepickerConfig>;
  public totalUserActivitydata: any;
  public moreRecordsThanDisplayed: boolean = false;
  public error: any;
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger = new Subject();
  constructor(
    private http: HttpClient,
    private mainDetailsService: MainDetailsService,
    private userActivityService: UserActivityService,
    public datepipe: DatePipe
  ) {
    this.datePickerConfig = Object.assign(
      {},
      { dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false }
    );
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngOnInit() {
    //let moment = require('moment'); // add this 1 of 4
    let now = moment();
    this.toDate = now.format('DD/MM/YYYY');
    this.fromDate = now.subtract(1, 'days').format('DD/MM/YYYY');
    $('#toDate').val(this.toDate);
    $('#fromDate').val(this.fromDate);
    console.log('toDate is' + this.toDate);
    console.log('fromDate is' + this.fromDate);
    var toDate = moment().format('YYYY-MM-DD');
    var fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD'); //new Date(this.fromDate).toISOString();
    this.createDataTableOptions();
  }

  /*Language options for future use if required otherwise delete in final cut */
  /* "language": {
            "lengthMenu": "Display _MENU_ records per page",
            "zeroRecords": "Nothing found - sorry",
            "info": "Showing page _PAGE_ of _PAGES_",
            "infoEmpty": "No records available",
            "infoFiltered": "(filtered from _MAX_ total records)"
        } */

  createDataTableOptions() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      order: [3, 'desc'],
      language: {
        lengthMenu: 'Show _MENU_ entries per page',
      },
    };
  }
  getUserActivityTableData(
    format,
    limit,
    branchDealerCode,
    fromDate,
    toDate,
    accessToken
  ) {
    this.loadingSpinner = true;
    this.mainDetailsService.ping(accessToken).subscribe(
      (result) => {
        this.userActivityService
          .userActivityTable(
            format,
            limit,
            branchDealerCode,
            fromDate,
            toDate,
            accessToken
          )
          .subscribe(
            (res) => {
              console.log('result is:' + res);
              if (res == null) {
                this.totalUserActivitydata = 0;
                this.userActivityDataset = [];
              } else {
                this.totalUserActivitydata = res.total || 0;
                if (this.totalUserActivitydata > limit) {
                  this.moreRecordsThanDisplayed = true;
                } else {
                  this.moreRecordsThanDisplayed = false;
                }
                this.userActivityDataset = res.auditLogs || [];
              }
              this.dtTrigger.next();
              this.loadingSpinner = false;
            },
            (error) => {
              console.log('result is#########################:' + error);
              this.error = 'Service unavailable';
              this.loadingSpinner = false;
            }
          );
      },
      (err) => {
        console.log(err);
        if (err.status == '401') {
          console.log('redirect url' + JSON.parse(err.error).redirectUri);
          var redirectUri = JSON.parse(err.error).redirectUri;
          window.location.href = redirectUri;
        }
        this.userActivityService
          .userActivityTable(
            this.format,
            this.limit,
            this.branchDealerCode,
            this.fromDate,
            this.toDate,
            localStorage.getItem('id_token')
          )
          .subscribe(
            (res) => {
              this.loadingSpinner = false;
            },
            (error) => {
              this.loadingSpinner = false;
            }
          );
      }
    );
  }

  loadUserActivity() {
    console.log('user activity clicked');
    $('#toDate').val(this.toDate);
    $('#fromDate').val(this.fromDate);
    this.getUserActivityTableData(
      'json',
      500,
      $('#branchDealerCode').val(),
      this.fromDate,
      this.toDate,
      localStorage.getItem('id_token')
    );
  }

  showUserActivity() {
    console.log('button clicked!!!!!!!!!!!!!!!!!!!!!!!!!');
    this.loadingSpinner = true;
    //this.fromDate = this.datepipe.transform(this.fromDate, 'yyyyMMddThhmmssZ');
    // this.toDate = this.datepipe.transform(this.toDate, 'yyyyMMddThhmmssZ');
    var table = $('#userActivityTable').DataTable();
    table.destroy();
    this.dtOptions = {};
    this.moreRecordsThanDisplayed = false;
    this.createDataTableOptions();
    var fromDate, toDate;
    if (this.fromDate == moment().subtract(1, 'days').format('DD/MM/YYYY')) {
      fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD'); //.toISOString()
    } else {
      fromDate = moment(this.fromDate).format('YYYY-MM-DD'); //.toISOString();
    }
    if (this.toDate == moment().format('DD/MM/YYYY')) {
      toDate = moment().format('YYYY-MM-DD'); //.toISOString()
    } else {
      toDate = moment(this.toDate).format('YYYY-MM-DD'); //.toISOString();
    }
    //var fromDate = moment(this.fromDate).toISOString();//this.datepipe.transform(this.fromDate, 'yyyyMMddhhmmss');//new Date(this.fromDate).toISOString();
    // var toDate = moment(this.toDate).toISOString();//this.datepipe.transform(this.toDate, 'yyyyMMddhhmmss');
    console.log('toDate is' + toDate);
    console.log('fromDate is' + fromDate);
    this.getUserActivityTableData(
      'json',
      500,
      $('#branchDealerCode').val(),
      fromDate + 'T00:00:00.000Z',
      toDate + 'T23:59:59.000Z',
      localStorage.getItem('id_token')
    );
  }

  exportToCSV() {
    console.log('csv button clicked!!!!!!!!!!!!!!!!!!!!!!!!!');
    //this.fromDate = this.datepipe.transform(this.fromDate, 'dd/MM/yyyy');
    //this.toDate = this.datepipe.transform(this.toDate, 'dd/MM/yyyy');
    var fromDate, toDate;
    if (this.fromDate == moment().subtract(1, 'days').format('DD/MM/YYYY')) {
      fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD'); //.toISOString()
    } else {
      fromDate = moment(this.fromDate).format('YYYY-MM-DD'); //.toISOString();
    }
    if (this.toDate == moment().format('DD/MM/YYYY')) {
      toDate = moment().format('YYYY-MM-DD'); //.toISOString()
    } else {
      toDate = moment(this.toDate).format('YYYY-MM-DD'); //.toISOString();
    }
    this.mainDetailsService.ping(localStorage.getItem('id_token')).subscribe(
      (result) => {
        this.userActivityService
          .userActivityTable(
            'csv',
            '',
            '',
            fromDate + 'T00:00:00.000Z',
            toDate + 'T23:59:59.000Z',
            localStorage.getItem('id_token')
          )
          .subscribe(
            (res) => {
              window.open(res.downloadUri, '_self');
            },
            (error) => {
              console.log('result is#########################:' + error);
              this.error = 'Service unavailable';
            }
          );
      },
      (err) => {
        console.log(err);
        if (err.status == '401') {
          console.log('redirect url' + JSON.parse(err.error).redirectUri);
          var redirectUri = JSON.parse(err.error).redirectUri;
          window.location.href = redirectUri;
        }
      }
    );
  }
}
