import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  public headers: HttpHeaders;
  constructor(private http: HttpClient) { }

  public userActivityTable(format, limit, branchDealerCode, fromDate, toDate, accessToken): Observable<any> {
    console.log("in user activity service");
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', accessToken);
    return this.http.get("/v1/user-activities",
      { headers: this.headers, params: { format: format, limit: limit, fromDate: fromDate, toDate: toDate } },
    );
  }

}
