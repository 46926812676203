import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Optional,
  Inject,
  forwardRef,
} from '@angular/core';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { MatFileUploadQueue } from '../matFileUploadQueue/matFileUploadQueue.component';

/**
 * A material design file upload component.
 */
@Component({
  selector: 'mat-file-upload',
  templateUrl: `./matFileUpload.component.html`,
  exportAs: 'matFileUpload',
  host: {
    class: 'mat-file-upload',
  },
  styleUrls: ['./../matFileUploadQueue.css'],
})
export class MatFileUpload implements OnDestroy {
  constructor(
    private HttpClient: HttpClient,
    @Inject(forwardRef(() => MatFileUploadQueue))
    public matFileUploadQueue: MatFileUploadQueue
  ) {
    if (matFileUploadQueue) {
      this.httpUrl = matFileUploadQueue.httpUrl || this.httpUrl;
      this.httpRequestHeaders =
        matFileUploadQueue.httpRequestHeaders || this.httpRequestHeaders;
      this.httpRequestParams =
        matFileUploadQueue.httpRequestParams || this.httpRequestParams;
      this.fileAlias = matFileUploadQueue.fileAlias || this.fileAlias;
    }
  }

  isUploading: boolean = false;

  /* Http request input bindings */
  @Input()
  httpUrl: string = 'http://localhost:8080';

  @Input()
  httpRequestHeaders:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      } = new HttpHeaders();

  @Input()
  httpRequestParams:
    | HttpParams
    | {
        [param: string]: string | string[];
      } = new HttpParams();

  @Input()
  fileAlias: string = 'file';

  @Input()
  get file(): any {
    return this._file;
  }
  set file(file: any) {
    this._file = file;
    this.total = this._file.size;
  }

  @Input()
  set id(id: number) {
    this._id = id;
  }
  get id(): number {
    return this._id;
  }

  /** Output  */
  @Output() removeEvent = new EventEmitter<MatFileUpload>();
  @Output() onUpload = new EventEmitter();

  progressPercentage: number = 0;
  public loaded: number = 0;
  total: number = 0;
  private _file: any;
  private _id: number;
  private fileUploadSubscription: any;
  public disableUploadIcon: boolean = false;
  public fileUploaded: any = [];
  public fileAlreadyUploaded: boolean = false;
  public fileType: string;

  public upload(): void {
    for (var i = 0; i < this.fileUploaded.length; i++) {
      if (this._file.name == this.fileUploaded[i]) {
        this.fileAlreadyUploaded = true;
        break;
      }
    }

    if (!this.fileAlreadyUploaded) {
      this.isUploading = true;
      this.httpRequestParams = new HttpParams().set(
        'fileName',
        this._file.name
      );
      let formData = new FormData();
      formData.append(this.fileAlias, this._file, this._file.name);
      // How to set the alias?
      this.HttpClient.get(this.httpUrl, {
        headers: this.httpRequestHeaders,
        params: this.httpRequestParams,
        responseType: 'json',
      }).subscribe(
        (event: any) => {
          console.log(`S3 getURL Response:  ${JSON.stringify(event)}`);
          const headers = new HttpHeaders();
          headers.set('Content- Type', 'application/pdf');
          this.progressBar();
          this.fileUploadSubscription = this.HttpClient.request(
            'PUT',
            event.uploadURL,
            {
              body: this._file,
              headers: headers,
              reportProgress: true,
              responseType: 'json',
            }
          ).subscribe(
            (uploadEvent: any) => {
              console.log(
                `S3 Upload file Response:  ${JSON.stringify(uploadEvent)}`
              );
              this.progressPercentage = 100;
              this.loaded = this._file.size;
              //i = this._file.size;
              console.log(
                `Progress: ${this.progressPercentage}, loaded: ${this.loaded}, total: ${this.total}`
              );
              this.fileUploaded.push(this._file.name);
              this.onUpload.emit({
                file: this._file,
                event: event,
                path: event.key,
              });
              this.disableUploadIcon = true;
            },
            (error: any) => {
              alert('Error when uploading');
              if (this.fileUploadSubscription) {
                this.fileUploadSubscription.unsubscribe();
              }
              this.isUploading = false;
              this.onUpload.emit({ file: this._file, event: event });
              //i = this._file.size;
            },
            () => {
              console.log(`PUT S3 called successfully invoked`);
            }
          );
          //}
        },
        (error: any) => {
          console.log(`An error occured fetching upload URL ${error}`);
        },
        () => {
          console.log(`Get successfully invoked`);
        }
      );
    }
  }

  public progressBar(): void {
    setTimeout(function () {
      this.progressPercentage += 10;
      if (this.progressPercentage < 70) {
        this.progressBar();
      }
    }, 100);
  }

  public remove(): void {
    if (this.fileUploadSubscription) {
      this.fileUploadSubscription.unsubscribe();
    }
    this.removeEvent.emit(this);
    for (var i = 0; i < this.fileUploaded.length; i++) {
      if (this.fileUploaded[i] === this._file.name) {
        this.fileUploaded.splice(i, 1);
      }
    }
  }

  ngOnDestroy() {
    console.log('file ' + this._file.name + ' destroyed...');
  }
}