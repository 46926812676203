import { Component, OnInit } from '@angular/core';
import { MainDetailsService } from '../main-details.service';

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.css']
})
export class HeaderPanelComponent implements OnInit {
  public username:any;

  constructor(private mainDetailsService : MainDetailsService ) { }

  ngOnInit() {
    this.mainDetailsService.ping(localStorage.getItem('id_token')).subscribe( 
      result => { 
        console.log(result+"result");       
        this.username = result.get('firstName')+" "+result.get('lastName');
        }
    , error =>{
      console.log(error)
    } )

  }

}
