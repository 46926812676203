import { Component, OnInit, OnDestroy, QueryList, ViewChildren, Input, ContentChildren, forwardRef } from '@angular/core';
import { MatFileUpload } from '../matFileUpload/matFileUpload.component';
import { Subscription } from 'rxjs';
import { merge } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';


/**
 * A material design file upload queue component.
 */
@Component({
  selector: 'mat-file-upload-queue',
  templateUrl: `matFileUploadQueue.component.html`,
  exportAs: 'matFileUploadQueue',
})
export class MatFileUploadQueue implements OnDestroy {

  public sizeError: boolean = false;
  public typeError: boolean = false;
  public duplicateError: boolean = false;
  @ContentChildren(forwardRef(() => MatFileUpload)) fileUploads: QueryList<MatFileUpload>;

  /** Subscription to remove changes in files. */
  private _fileRemoveSubscription: Subscription | null;

  /** Subscription to changes in the files. */
  private _changeSubscription: Subscription;

  /** Combined stream of all of the file upload remove change events. */
  get fileUploadRemoveEvents() {
    return merge(...this.fileUploads.map(fileUpload => fileUpload.removeEvent));
  }

  files: Array<any> = [];

  /* Http request input bindings */
  @Input()
  httpUrl: string;

  @Input()
  httpRequestHeaders: HttpHeaders | {
    [header: string]: string | string[];
  } = new HttpHeaders();

  @Input()
  httpRequestParams: HttpParams | {
    [param: string]: string | string[];
  } = new HttpParams();

  @Input()
  fileAlias: string = "file";

  ngAfterViewInit() {
    // When the list changes, re-subscribe
    this._changeSubscription = this.fileUploads.changes.pipe(startWith(null)).subscribe(() => {
      if (this._fileRemoveSubscription) {
        this._fileRemoveSubscription.unsubscribe();
      }
      this._listenTofileRemoved();
    });
  }

  private _listenTofileRemoved(): void {
    this._fileRemoveSubscription = this.fileUploadRemoveEvents.subscribe((event: MatFileUpload) => {
      this.files.splice(event.id, 1);
    });
  }

  add(file: any) {
    console.log("file.size" + file.size + "//file.name" + file.name);
    console.log(file);
    if (file.size < 2147483648 && file.type == 'application/pdf') {
      console.log("in if");
      this.sizeError = false;
      this.typeError = false;
      console.log("files in matFileUploadQueue"+this.files);
      console.log("index of new file in matFileUploadQueue"+this.files.findIndex(f=> f.name === file.name ))
      if(this.files.findIndex(f=> f.name === file.name )== -1){
        this.files.push(file);
        this.duplicateError = false;
      }
      else{
        this.duplicateError = true;
      }
    }
    else if (file.type != 'application/pdf') {
      this.typeError = true;
    }
    else {
      console.log("in else");
      this.sizeError = true;
      // alert("File size should be less than 2 gb");
    }
  }

  public uploadAll() {
    this.fileUploads.forEach((fileUpload) => { fileUpload.upload() });
  }

  public removeAll() {
    this.files.splice(0, this.files.length);
    this.fileUploads.forEach((fileUpload) => { fileUpload.remove() });
  }

  ngOnDestroy() {
    if (this.files) {
      this.removeAll();
    }
  }

  public closeTypeAlert() {
    this.typeError = false;
  }
  public closeSizeAlert() {
    this.sizeError = false;
  }
  public closeDuplicateAlert(){
    this.duplicateError = false;
  }

}