import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  Input,
} from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { UpdateDocumentsService } from '../update-documents.service';
import { MainDetailsService } from '../main-details.service';
import { NgForm } from '@angular/forms';
import { MatFileUploadQueue } from '../../lib/matFileUpload';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-update-documents',
  templateUrl: './update-documents.component.html',
  styleUrls: ['./update-documents.component.css'],
  //providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateDocumentsComponent implements OnInit {
  public model: any;
  public selectedForm: any;
  public selectedContractFormName: any;
  public showFormFields: boolean = false;
  public formType: any = [];
  public formData: any = [];
  public fields: any = [];
  public data: any = [];
  public infoMessage: any;
  public error: boolean = false;
  public formSubmitted: boolean = false;
  public fileUploaded: boolean = false;
  public idUploaded: boolean = false;
  public minUploadedDoc: any;
  public minUloadedId: any;
  public loading: boolean = false;
  public submitSuccessful: boolean = false;
  public submitFailed: boolean = false;
  public isDocAlreadyUploaded: boolean = false;
  public isIdAlreadyUploaded: boolean = false;
  public isClickedOnce: number = 0;
  public uploadPath: any = [];
  public numberOfDocsUploaded: any = 0;
  public numberOfIdsUploaded: any = 0;
  public allowMultipleMSISDN: boolean = false;
  public MSISDNCounter: number = 0;
  public showField: any = [];
  public mobile: any = [];
  public minDate: Date = new Date('01/01/1900');
  public maxDate: Date = new Date('12/31/2199');
  public defaultDate: Date = new Date('02/01/2000');
  datePickerConfig: Partial<BsDatepickerConfig>;
  public mandatoryfieldMissingText: string =
    'Mandatory Field please enter data';
  public minimumLengthText: string =
    'Data entered does not meet Min length required';
  public maximumLengthText: string = 'Data entered exceeds max length required';
  public dataTypeMismatchText: string =
    'Data entered does not match format required';
  public orderNumberFormatMismatchText: string =
    "Data should start with a number followed by a '-' then 13 numbers (for ex: 3-1234567891011)";
  public mobileNumberFormatMismatchText: string =
    'Data should start with 61 and should not be longer than 11 numbers (for ex: 61411123123)';
  public successfulSubmissionText: string = 'Upload successful';
  public fileSizeLimitExceeded: string = 'File size is too big';
  public spaceNotAllowedText: string = 'Blankspaces are not allowed';
  public submitErrorMessage: string = 'Upload Failed';

  @Input()
  httpRequestDocHeaders:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      } = new HttpHeaders()
    .set('Authorization', localStorage.getItem('id_token'))
    .set('documentType', 'document');

  httpRequestIdHeaders:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      } = new HttpHeaders()
    .set('Authorization', localStorage.getItem('id_token'))
    .set('documentType', 'id');

  /*@Input()
   httpRequestParams: HttpParams | {
     [param: string]: string | string[];
   } = new HttpParams().set("sampleRequestParam", "requestValue").set("sampleRequestParam1", "requestValue1");*/

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    private updateDocumentsService: UpdateDocumentsService,
    private mainDetailsService: MainDetailsService
  ) {
    // customize default values of typeaheads used by this component tree
    //config.showHint = true;
    this.datePickerConfig = Object.assign(
      {},
      { dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false }
    );
  }
  ngOnInit() {
    this.updateDocumentsService
      .formType(localStorage.getItem('id_token'))
      .subscribe((data) => {
        console.log('formtype:' + data.contractFormTypes.length);
        var temp = data.contractFormTypes;
        for (let i = 0; i < temp.length; i++) {
          this.formType.push(temp[i].description);
          console.log('title:' + temp[i].description);
          this.formData.push(temp[i]);
        }
        // this.loading = false;
      });
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? this.formType
          : this.formType.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 200)
      )
    );
  };

  selectedItem(item) {
    this.error = false;
    this.fileUploaded = false;
    this.idUploaded = false;
    this.data = [];
    this.formSubmitted = false;
    this.selectedForm = item.item;
    this.uploadPath = [];
    this.loading = true;
    if (this.selectedForm == 'Mobile Service Contract 2019 Siebel Generated') {
      this.allowMultipleMSISDN = true;
    } else {
      this.allowMultipleMSISDN = false;
      this.MSISDNCounter = 0;
      for (var i = 0; i <= 10; i++) {
        this.showField[i] = false;
      }
    }

    //this.formData = this.updateDocumentsService.uploadData[1];
    for (var i = 0; i < this.formData.length; i++) {
      if (this.formData[i].description == this.selectedForm) {
        this.infoMessage = this.formData[i].infoMessage;
        this.minUploadedDoc = this.formData[i].minNumberOfDocuments;
        this.selectedContractFormName = this.formData[i].name;
        this.minUloadedId = this.formData[i].minNumberOfIds;
        if (this.minUploadedDoc == 0) {
          this.fileUploaded = true;
        }
        if (this.minUloadedId == 0) {
          this.idUploaded = true;
        }
        this.fields = [];
        for (var j = 0; j < this.formData[i].attributes.length; j++) {
          this.fields.push(this.formData[i].attributes[j]);
        }
      }
    }
    this.loading = false;
    this.showFormFields = true;
    console.log(item);
    console.log('model:' + this.model);
  }
  noItemSelected(item) {
    console.log('model:' + this.model);
    console.log('cleared' + item + '.....yes');
    if (this.model == '' || this.model == undefined) {
      this.showFormFields = false;
      this.data = [];
      this.formSubmitted = false;
    } else {
      this.showFormFields = true;
    }
  }
  addMobilefields() {
    this.MSISDNCounter++;
    if (this.MSISDNCounter <= 9) {
      for (var i = 1; i <= this.MSISDNCounter; i++) {
        this.showField[i] = true;
      }
    }
  }

  removeMobilefield() {
    this.showField[this.MSISDNCounter] = false;
    this.mobile[this.MSISDNCounter] = '';
    this.MSISDNCounter--;
  }
  onSubmit(f: NgForm) {
    this.formSubmitted = true;
    this.error = false;
    var spaceAllowedFields = [
      'Dealer Code',
      'CX First Name',
      'CX Last Name',
      'ABN/ ACN',
      'Hardware Account Number',
    ];
    var nullValueAllowedFields = [
      'Order Number',
      'Customer Account number',
      'Mobile Number',
      'CX First Name',
      'CX Last Name',
      'ABN/ACN',
      'Hardware Account Number',
      'Customer Row ID',
      'Contact ID',
    ];
    this.error = false;
    for (var j = 0; j < this.fields.length; j++) {
      this.fields[j].error = '';
      if (this.data[j] == undefined || this.data[j] == '') {
        var counter = 0;
        for (var index = 0; index < nullValueAllowedFields.length; index++) {
          if (this.fields[j].name.trim() === nullValueAllowedFields[index]) {
            counter++;
          }
        }
        if (counter == 0) {
          if (this.fields[j].name.trim() !== 'Connection Date') {
            this.fields[j].error = this.mandatoryfieldMissingText;
            this.error = true;
          } else {
            this.data[j] = this.defaultDate;
          }
        }
      } else if (
        this.data[j].toString().indexOf(' ') !== -1 &&
        this.fields[j].validationType !== 'DATE'
      ) {
        var counter = 0;
        for (var index = 0; index < spaceAllowedFields.length; index++) {
          if (this.fields[j].name.trim() === spaceAllowedFields[index]) {
            counter++;
          }
        }
        if (counter == 0) {
          this.fields[j].error = this.spaceNotAllowedText; //this.fields[j].errorMessage;
          this.error = true;
        }
      } else if (this.fields[j].validationType === 'ALPHANUMERIC') {
        if (this.fields[j].name === 'Order number ') {
          if (this.data[j].length != 15) {
            this.fields[j].error = this.orderNumberFormatMismatchText;
            this.error = true;
          } else {
            var regExp = /^[1-9](-)[0-9]{13}$/;
            if (regExp.test(this.data[j])) {
              this.fields[j].error = '';
            } else {
              this.fields[j].error = this.orderNumberFormatMismatchText; //this.fields[j].errorMessage;
              this.error = true;
            }
          }
        } else if (this.data[j].length < this.fields[j].minLength) {
          this.fields[j].error = this.minimumLengthText; //this.fields[j].errorMessage;
          this.error = true;
        } else if (this.data[j].length > this.fields[j].maxLength) {
          this.fields[j].error = this.maximumLengthText; //this.fields[j].errorMessage;
          this.error = true;
        }
      } else if (this.fields[j].validationType === 'NUMERIC') {
        if (this.fields[j].name === 'Mobile Number ') {
          if (this.data[j].length != 11) {
            this.fields[j].error = this.mobileNumberFormatMismatchText;
            this.error = true;
          } else {
            var regExp = /(61)[0-9]{9}$/;
            if (regExp.test(this.data[j])) {
              this.fields[j].error = '';
            } else {
              this.fields[j].error = this.mobileNumberFormatMismatchText; //this.fields[j].errorMessage;
              this.error = true;
            }
          }
        } else if (this.data[j].length < this.fields[j].minLength) {
          this.fields[j].error = this.minimumLengthText; //this.fields[j].errorMessage;
          this.error = true;
        } else if (this.data[j].length > this.fields[j].maxLength) {
          this.fields[j].error = this.maximumLengthText; //this.fields[j].errorMessage;
          this.error = true;
        }
      }

      if (this.fields[j].validationType === 'DATE') {
        if (this.data[j].length < this.fields[j].minLength) {
          this.fields[j].error = this.minimumLengthText; //this.fields[j].errorMessage;
          this.error = true;
        } else if (this.data[j].length > this.fields[j].maxLength) {
          this.fields[j].error = this.maximumLengthText; //this.fields[j].errorMessage;
          this.error = true;
        } else {
          let date = new Date(this.data[j]);
          if (date < this.minDate || date > this.maxDate) {
            this.fields[j].error = this.dataTypeMismatchText; //this.fields[j].errorMessage;
            this.error = true;
          }
        }
      }
    }

    if (!this.error && this.numberOfDocsUploaded >= 1) {
      var updateData = {};
      var temp = [];
      var documentPaths = {};
      for (var i = 0; i < this.data.length; i++) {
        var fieldName, fieldData;
        if (this.fields[i].name.trim() == 'Mobile Number') {
          fieldName = this.fields[i].name;
          fieldData =
            this.data[i] === null || this.data[i] === undefined
              ? ' '
              : this.data[i];
          for (var j = 1; j <= this.MSISDNCounter; j++) {
            fieldData = fieldData + ',' + this.mobile[j];
          }
        } else {
          fieldName = this.fields[i].name;
          fieldData =
            this.data[i] === null || this.data[i] === undefined
              ? ' '
              : this.data[i];
        }

        var date = '';
        if (this.fields[i].dataType == 'date') {
          date = moment(fieldData).toISOString();
          fieldData = date;
        }

        temp.push({
          name: fieldName,
          value: fieldData,
        });
      }
      //Remove any null values from the path array
      var filteredPath = this.uploadPath.filter(function (el) {
        return el != null;
      });
      console.log('filteredPath length is:' + filteredPath.length);
      documentPaths = { paths: filteredPath };
      var selectedFormType = f.value.formType;
      this.isClickedOnce++;
      updateData = {
        name: this.selectedContractFormName,
        numberOfDocsUploaded: this.numberOfDocsUploaded,
        numberOfIdsUploaded: this.numberOfIdsUploaded,
        attributes: temp,
        documents: documentPaths,
      };

      if (this.isClickedOnce === 1) {
        this.mainDetailsService
          .ping(localStorage.getItem('id_token'))
          .subscribe(
            (result) => {
              this.updateDocumentsService
                .submit(
                  JSON.stringify(updateData),
                  localStorage.getItem('id_token')
                )
                .subscribe(
                  (res) => {
                    f.resetForm();
                    this.MSISDNCounter = 0;
                    for (var i = 0; i <= 10; i++) {
                      this.showField[i] = false;
                    }
                    f.controls['formType'].setValue(selectedFormType);
                    $('.removeAll').click();
                    this.numberOfDocsUploaded = 0;
                    this.numberOfIdsUploaded = 0;
                    this.isIdAlreadyUploaded = false;
                    this.isDocAlreadyUploaded = false;
                    this.submitSuccessful = true;
                    this.submitFailed = false;
                    this.uploadPath = [];
                    this.error = false;
                    this.isClickedOnce = 0;
                  },
                  (error) => {
                    this.submitErrorMessage =
                      this.submitErrorMessage +
                      ', ' +
                      JSON.stringify(error.error);
                    this.submitSuccessful = false;
                    this.submitFailed = true;
                    this.isClickedOnce = 0;
                  }
                );
            },
            (err) => {
              this.isClickedOnce = 0;
              console.log(err);
              if (err.status == '401') {
                console.log('redirect url' + JSON.parse(err.error).redirectUri);
                var redirectUri = JSON.parse(err.error).redirectUri;
                window.location.href = redirectUri;
              }
              this.updateDocumentsService
                .submit(
                  JSON.stringify(updateData),
                  localStorage.getItem('id_token')
                )
                .subscribe(() => {
                  console.log('submitted');
                });
            }
          );
      }
    }
  }

  public uploadDocumentEvent($event: any) {
    this.fileUploaded = true;
    if (this.uploadPath.length != 0) {
      for (var i = 0; i < this.uploadPath.length; i++) {
        if (this.uploadPath[i].split('/')[2] == $event.path.split('/')[2]) {
          this.isDocAlreadyUploaded = true;
          break;
        }
      }
      if (!this.isDocAlreadyUploaded) {
        this.numberOfDocsUploaded = this.numberOfDocsUploaded + 1;
        this.uploadPath.push($event.path);
      }
    } else {
      this.numberOfDocsUploaded = this.numberOfDocsUploaded + 1;
      this.uploadPath.push($event.path);
    }
  }

  public uploadIdEvent($event: any) {
    this.idUploaded = true;
    if (this.uploadPath.length != 0) {
      for (var i = 0; i < this.uploadPath.length; i++) {
        if (this.uploadPath[i].split('/')[2] == $event.path.split('/')[2]) {
          this.isIdAlreadyUploaded = true;
          break;
        }
      }
      if (!this.isIdAlreadyUploaded) {
        this.numberOfIdsUploaded = this.numberOfIdsUploaded + 1;
        this.uploadPath.push($event.path);
      }
    } else {
      this.numberOfIdsUploaded = this.numberOfIdsUploaded + 1;
      this.uploadPath.push($event.path);
    }
  }
  public removeDocument($event: any) {
    for (var i = 0; i < this.uploadPath.length; i++) {
      if (this.uploadPath[i].indexOf('/' + $event.file.name) > -1) {
        this.numberOfDocsUploaded = this.numberOfDocsUploaded - 1;
        this.uploadPath.splice(i, 1);
        if (this.minUploadedDoc > this.numberOfDocsUploaded) {
          this.fileUploaded = false;
        }
      }
    }
  }

  public removeId($event: any) {
    for (var i = 0; i < this.uploadPath.length; i++) {
      if (this.uploadPath[i].indexOf('/ID_' + $event.file.name) > -1) {
        this.numberOfIdsUploaded = this.numberOfIdsUploaded - 1;
        this.uploadPath.splice(i, 1);
        if (this.minUloadedId > this.numberOfIdsUploaded) {
          this.idUploaded = false;
        }
      }
    }
  }

  checkFormType() {
    console.log('in check:' + this.model);
  }

  closeSubmitSuccessAlert() {
    this.submitSuccessful = false;
  }

  closeSubmitFailedAlert() {
    this.submitFailed = false;
  }
}
