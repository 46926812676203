import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
//import { DataTableModule } from "angular-6-datatable";
import { HttpClientModule } from '@angular/common/http';
import { FileUploadModule } from 'ng2-file-upload';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
//start//
import { MatButtonModule } from '@angular/material';
import { MatFileUploadModule } from '../lib/matFileUpload.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//end//
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DataTablesModule } from 'angular-datatables';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { MainDetailsComponent } from './main-details/main-details.component';
import { UpdateDocumentsComponent } from './update-documents/update-documents.component';
import { UserActivityReportComponent } from './user-activity-report/user-activity-report.component';
import { HeaderPanelComponent } from './header-panel/header-panel.component';
import { FooterPanelComponent } from './footer-panel/footer-panel.component';
//import { MainDetailsService } from './main-details.service';
//import { FileUploadPrimeNGComponent } from './file-upload-prime-ng/file-upload-prime-ng.component';

@NgModule({
  declarations: [
    AppComponent,
    MainDetailsComponent,
    UpdateDocumentsComponent,
    UserActivityReportComponent,
    HeaderPanelComponent,
    FooterPanelComponent,
    //FileUploadPrimeNGComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    FileUploadModule,
    AngularFontAwesomeModule,
    MatButtonModule,
    MatFileUploadModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    DataTablesModule,
    RouterModule.forRoot([]),
    BsDatepickerModule.forRoot(),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
