import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainDetailsService {
  public headers: HttpHeaders ;


  constructor(private http: HttpClient) { }

  /**
   * ping method
   */
  public ping(accessToken): Observable<any> {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', accessToken);
    console.log("in ping service");
    return this.http.get("/v1/ping", {  observe: 'response', headers:  this.headers, responseType: 'text' })
      .pipe(map( // Log the result or error
        data => {
          console.log("Data: ", data);
          return data.headers;
        },
        error => {
          console.log("Error: ", error);
          return error;
        }
      ))
    /*.pipe(map(response =>  { 
      console.log("response in service is "+response);
      console.log("response.status"+response.status);
     // If request fails, throw an Error that will be caught 
     if(response.status != 204) { 
     throw new Error('This request has failed ' + response.status); } // If everything went fine, return the response 
     else {
       console.log("in else");
      return response; 
     } }))*/
    /*.subscribe((res)=>{
     console.log(res.headers.get('X-Token'));
     return res.headers;
    },(err)=>{
      console.log("in Error");
     return err;
    });*/
  }
}
